import React from "react";
import { navigate } from "gatsby"
import Title from "../title/title";
import DSEWrapper from "../DSEWrapper/DSEWrapper";
import IconTitleText from "../IconTitleText/IconTitleText";
import Ribbon from "../ribbon/ribbon";
import icon1 from "../../../assets/images/pages/dse-home/types-icon1.svg";
import icon2 from "../../../assets/images/pages/dse-home/types-icon2.svg";
import icon3 from "../../../assets/images/pages/dse-home/types-icon3.svg";
import DseCtaIcon from "../DseCtaIcon/DseCtaIcon";


const WhatNpc = () => {
  const clickHandler = () => {
    navigate('/join-NPCFacts-community');
  };
  return (
    <section className="dse-what-npc dse-grad-bg8" id="what-is-npc">
      <DSEWrapper className="dse-what-npc__title" fullWidth={true}>
        <Title text="CAN NASOPHARYNGEAL CANCER COME BACK?"/>
      </DSEWrapper>

      <DSEWrapper>
        <div className="dse-what-npc__intro">
          <div className="dse-what-npc__intro-text">
            <p>NPC can come back at or near the original tumor after being treated (recurrent locally advanced) or spread to other areas of the body (metastatic). This is also known as R/M NPC.</p>
          </div>
          <IconTitleText title="Recurrent locally <br/> advanced NPC">
            <p>The cancer has come back at or near the same place as the original tumor.</p>
          </IconTitleText>
          <IconTitleText title="Metastatic NPC">
            <p>The cancer has spread to other areas of the body.</p>
          </IconTitleText>
        </div>

      </DSEWrapper>

      <DSEWrapper className="dse-what-npc__title" fullWidth={true}>
        <Title text="WHAT ARE THE TYPES OF NASOPHARYNGEAL CANCER?"/>
      </DSEWrapper>

      <DSEWrapper>
      <div className="dse-what-npc__types">
          <h3 className="dse-heading3 dse-light-text text-left">There are 3 main types of nasopharyngeal cancer:</h3>
          <div className="dse-what-npc__types-list">
            <IconTitleText icon={icon1} title="Keratinizing<br/>squamous<br/>cell cancer" iconAlt="">
              <p><strong>Keratinizing</strong> cells produce an excess amount of protein called keratin. Keratin hardens the outermost layer of the tumor.</p>
              <p><strong>Both types 2 and 3 are nonkeratinizing</strong>, which means hardening does not happen.</p>
            </IconTitleText>
            <IconTitleText icon={icon2} title="Differentiated<br/>nonkeratinizing<br/>cell cancer" iconAlt="">
              <p><strong>Differentiated</strong> cells tend to grow and spread <em>slowly</em> to other parts of the body.</p>
            </IconTitleText>
            <IconTitleText icon={icon3} title="unDifferentiated<br/>nonkeratinizing<br/>cell cancer" iconAlt="">
              <p><strong>Undifferentiated</strong> cells tend to grow and spread <em>quickly</em> to other parts of the body.</p>
            </IconTitleText>
          </div>
          <Ribbon>
            <p>Regardless of which type of nasopharyngeal cancer you have, the treatment may be the same</p>
          </Ribbon>
          <DseCtaIcon text="See some of the risk factors and<br class='d-none d-md-block'/> symptoms of nasopharyngeal cancer" href="/risk-factors-and-symptoms/" />
        </div>
      </DSEWrapper>

    </section>
  )
}

export default WhatNpc;