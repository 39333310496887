import React from "react";
import DSEWrapper from "../DSEWrapper/DSEWrapper";
import Title from "../title/title";

const FaceParts = () => (
  <section className="dse-face-parts dse-solid-bg-face">
    <DSEWrapper fullWidth={true}>
      <Title text="WHAT IS NASOPHARYNGEAL CANCER?" isH1={true}/>
    </DSEWrapper>
    <DSEWrapper>
      <div className="dse-face-parts__intro">
        <div className="dse-face-parts__intro-text">
          <p>
            Nasopharyngeal cancer (or NPC) is a rare type of cancer that starts in
            the nasopharynx.
          </p>
          <p>
            The nasopharynx is located at the upper part of the throat behind the
            nose and near the base of the skull, as seen in the diagram below.
          </p>
        </div>
      </div>
    </DSEWrapper>
    <DSEWrapper fullWidth={true} className="dse-face-parts__img_bg">
      <picture>
        <source media="(min-width:768px)" srcSet='/images/Hero_Art_Side_Profile.png' />
        <img
          className="dse-face-parts__img"
          src='/images/Hero_Art_Side_Profile-m.png'
          alt="Profile view of a man’s head and throat, with a diagram of where the nasopharynx is located"
        />
      </picture>
    </DSEWrapper>
  </section>
);

export default FaceParts;
