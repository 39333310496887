import React, { memo } from 'react';
import DSELayout from "../components/DSE/DSELayout/DSELayout";
import DSEWrapper from "../components/DSE/DSEWrapper/DSEWrapper";
import WhatNpc from "../components/DSE/WhatNpc/WhatNpc";
import Glossary from "../components/DSE/Glossary/Glossary";
import FaceParts from "../components/DSE/FaceParts/FaceParts";
import squamosAudio from '../assets/audios/BL-0013_Squamous-cell_48k_stereo.mp3';

const GlossaryList = [
  {
    id: 'glossary-squamous-cell',
    title: 'Squamous cell',
    audio: squamosAudio,
    text: 'Flat cells located on the outer part of the epidermis that are constantly being shed as new cells form.'
  },
];

const About = (props) => {
  return (
    <React.Fragment>
      <DSELayout
        canonicalURL="https://npcfacts.com/about-nasopharyngeal-cancer"
        title="About nasopharyngeal cancer"
        pageTitle="Understanding Nasopharyngeal Cancer (NPC) | NPC.com"
        description="Nasopharyngeal cancer: learn where it’s located, how it can come back, and more"
        keyWords="Nasopharyngeal carcinoma; NPC treatments; NPC outcomes"
      >
        <DSEWrapper fullWidth={true}>
          <FaceParts />
        </DSEWrapper>
        <DSEWrapper fullWidth={true}>
          <WhatNpc />
          <Glossary className={'dse-grad8-last-color'} glossaryList={GlossaryList} />
        </DSEWrapper>
      </DSELayout>
    </React.Fragment>
  );
};

export default memo(About);
